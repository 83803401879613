<template>
	<Metier name="rh" title="Ressources Humaines" :main-menu="[{title: 'Assistant RH', missions: ['Réaliser les actes administratifs pour le personnel de la Plateforme', 'Gérer et suivre les plannings de quarts du personnel posté', 'Etablir et contrôler la paie des salariés', 'Rédiger les contrats et avenants au contrat de travail, les déclarations et les attestations de travail', 'Accompagner les salariés dans les différentes campagnes du Groupe (Intéressement et Participation, Chèques Vacances, Compte Epargne Temps, etc.)', 'Assurer la gestion de l\'intérim'], competences: ['Aptitudes aux relations Humaines', 'BAC+2 (BTS Assistant gestion PME/PMI, DUT RH) ou expérience équivalente'], qualites: ['Rigueur', 'Discrétion', 'Méthode', 'Ecoute', 'Attention', 'Diplomatie']}, {title: 'Technicien formation & gestion de carrières', missions: ['Assure la gestion administrative des recrutements et accueille les nouveaux arrivants', 'Publie les postes à pourvoir en interne et assure le suivi des mobilités du personnel', 'Centralise les demandes de formation des collaborateurs et de leur hiérarchie pour établir un plan de formation', 'Elabore et assure le suivi des contrats de stage et d\'alternance'], competences: ['Formation en Ressources Humaines', 'Connaissance de la plateforme et de son organisation', 'Connaissances des réglementations sur l\'administration et la formation du personnel', 'Maîtrise des outils informatiques'], qualites: ['Sens du contact', 'Confidentialité', 'Organisation']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
